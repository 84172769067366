<template>
  <CCard class="signature mt-4" color="light">
    <CCardBody>
      <VueSignaturePad ref="signature"/>
    </CCardBody>
    <CCardHeader>
      {{formLabel}}
      <CButton v-on:click="handleClear" color="secondary" size="sm" class="float-right">X</CButton>
    </CCardHeader>
  </CCard>
</template>

<script>
export default {
  name: 'formelement-signature',
  components: {
  },
  props: {
    formLabel: {
      default: 'Unterschrift',
      type: String
    }
  },
  data () {
    return {
    }
  },
  created () {
    const self = this
    this.$eventBus.$on('emit-signature', (data) => {
      self.createUploadFile(self)
    })
  },
  mounted () {
    // this.handleClear()
    // Verursacht Fehler weil refs noch nicht vorhanden sind
  },
  methods: {
    handleClear () {
      this.$refs.signature.clearSignature()
    },
    createUploadFile (obj) {
      const png = obj.$refs.signature.saveSignature()
      const blob = obj.pngToBlob(png.data)
      obj.$emit('input', blob)
    },
    pngToBlob (png) {
      const binary = atob(png.split(',')[1])
      const array = []
      let i = 0
      while (i < binary.length) {
        array.push(binary.charCodeAt(i))
        i++
      }
      return new Blob([new Uint8Array(array)], {
        type: 'image/png'
      })
    }
  }
}
</script>

<style lang="scss">

  div.signature {
    height: 16rem;
    width: 100%;
    font-size: 12px;
  }

  @media print {
    div.signature {
      height: 16rem;
      width: 450px !important;
    }
    .btn{
      display: none;
    }
    .card-header{
      border: none !important;
    }
    .signature {
      height: 120px !important;
      background-color: darkgray !important;
      .card-header{
        border-top: 1px solid gray !important;
      }
    }
    .signature canvas {
    }
  }
</style>
