<template>
  <div class="bilder-unterschriften">
    <div v-if="showImage">
      <img
        class=""
        v-auth-image="showImage"
      />
    </div>

    <div v-else class="noimage-wrapper">
      <span class="text-black-50 no-image-text">Keine Unterschrift</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'bilder-unterschriften',
  props: {
    unterschriften: [Object, Array],
    selektor: String
  },
  data: function () {
    return {
      imgserver: process.env.VUE_APP_MEDIA_BASEURL
    }
  },
  computed: {
    showImage () {
      let result = null
      if (this.unterschriften) {
        let imgFound = null
        const self = this
        Vue._.forEach(self.unterschriften, function (unterschrift) {
          if (unterschrift.selektor === self.selektor) {
            imgFound = unterschrift
          }
        })
        if (imgFound) {
          result = this.imgserver + '/api/_/media/unterschriften/thumb/' + imgFound.unterschriftid
        }
      }
      return result
    }
  }
}
</script>

<style>
  @media print {
    .noimage,.no-image-text {
      display: none;
    }

    div.noimage-wrapper{
      min-height: 80px;
    }
  }

  div.noimage-wrapper{
    min-height: 80px;
  }

 .bilder-unterschriften img{
   min-height: 80px;
   max-height: 80px;
   width: auto;
 }
</style>
